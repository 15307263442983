<template>
    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row justify="center">
            <v-col cols="12">
                <v-row justify="center" class="pa-lg-5" :style="angebote.length == 0 ? 'min-height: 50vh;' : ''">
                    <v-col cols="12" md="10"  align-self="center" v-if="angebote.length == 0" class="mt-5 text-center">
                      <h2 class="text-center">
                        Es wurden leider noch keine Angebote von deinem Verein erstellt.
                      </h2>
                      <v-btn class="mt-5" rounded color="primary" to="/verein/sportangebote/bearbeiten">Sportangebot erstellen</v-btn>
                    </v-col>
                    <v-col cols="12" md="5" v-for="(item, i) in angebote"
                    :key="i"> 
                      <sportangebotcard :item="item" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import sportangebotcard from '@/components/Sportangebot/card.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'Sportangebote-Uebersicht',
    components: {
        sportangebotcard
    },
    mounted(){
        this.nav.title = 'Sportangebote'
        this.nav.subtitle = 'Übersicht'
        this.nav.show = true
        this.nav.back_link = ''
    },
    computed: {
        ...mapGetters({
            user: 'user',
            vereine: 'vereine',
            db: 'db',
            sportarten: 'sportarten',
            sportangebote: 'sportangebote',
            nav: 'nav_expand'
        }),
        angebote(){
            return this.sportangebote.all.filter(item => item.verein?.id == this.user.data.verein?.id)
        }
    }
}
</script>